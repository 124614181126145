import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from "gatsby"
import {banner, bannerImgContainer, bannerCTAcontainer, cta, ctaButton, bannerSpecialInfoCTA} from "./banner.module.css"

export function Banner() {
    const border = {
        borderRadius: "0"
    }

    const mainCTA = "New Look. Same Functionality.";
    const sideInfo = "Educational and Bulk Discounts Available.";

    return (
        <section className={banner}>
            <div className={bannerImgContainer}>
                <StaticImage imgStyle={border} className={["animate__animated", "animate__pulse", "animate__infinite"].join(" ")} src="../images/rsid-field-kit-banner-1000px.jpg" alt="image of all the rsid field kits" />
            </div>
            <div className={bannerCTAcontainer}>
                <div>
                    <h2 className={cta}>{mainCTA}</h2>
                </div>
                <Link className={ctaButton} to="/search?p=Fluid%20ID">Explore Products</Link>
                <div className={bannerSpecialInfoCTA}>
                    <p><i>{sideInfo}</i></p>
                </div>
            </div>
        </section>
    )
}