import * as React from "react"
import { Link, graphql } from "gatsby"
import { Layout } from "../components/layout"
import { ProductListing } from "../components/product-listing"
import { Banner } from "../components/banner"
import {moreInfoSection, moreInfoDiv} from "./index.module.css"

export const query = graphql`
  query {
    shopifyCollection(
      handle: { eq: "featured" }) {
      products {
        ...ProductCard
      }
    }
  }
`

export default function IndexPage({ data }) {

  return (
    <Layout>
      <Banner />
      <section className={moreInfoSection}>
        <div className={moreInfoDiv}>
        <div className="container">
        <p>Welcome to our new Shopify online store. For more information about this site. <Link to="/about/" className="underline" >Click here.</Link></p>
        </div>
        </div>
      </section>
      <h1 className="titleCenter">Featured Products</h1>
      <ProductListing products={data?.shopifyCollection?.products} />
    </Layout>
  )
}
